import styled from "styled-components";

export const FooterSection = styled.div`
    margin: 60px 0;
`;

export const FooterContent = styled.div`
    padding-top: 40px;
    border-top: 2px solid #707070;

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: var(--primary-color);
        text-align: justify;
    }
`;

export const Notes = styled.p`
    strong,
    span,
    p {
        display: inline;
    }
`;
export const Disclaimer = styled.p`
    strong,
    p:first-child {
        display: inline;
    }
`;

export const CopyRightSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin-bottom: 0;
    }
`;
