import styled from "styled-components";
import { Images } from "../../config/images";

export const HeaderSection = styled.div`
    position: sticky;
    top: 0;
    z-index: 9999;

    &:before {
        content: "";
        background-image: url("${Images.Left}");
        position: absolute;
        width: 221px;
        height: 179px;
        left: 0;
        top: 0;
    }

    &:after {
        content: "";
        background-image: url("${Images.Right}");
        position: absolute;
        width: 229px;
        height: 238px;
        right: 0;
        top: 0;
    }

    &.sticky {
        background-color: var(--sticky-header-color);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

        &:before {
            content: unset;
        }

        &:after {
            content: unset;
        }
    }

    .logo {
        padding: 5px 0;

        a {
            display: inline-block;

            img {
                width: 290px;
                margin: 10px;
            }
        }
    }
`;
