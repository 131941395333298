import { Card } from "react-bootstrap";
import styled from "styled-components";

export const HomePage = styled.div``;

export const SearchSection = styled.div`
    margin-top: 80px;
`;

export const KnowledgeSection = styled.div`
    p {
        font-family: "Source Sans Pro";
        color: var(--color-333333);
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 0;
    }

    h2 {
        font-family: "Source Sans Pro";
        color: var(--color-3a3b40);
        font-size: 35px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 25px;
        margin-top: 10px;
    }

    .search {
        position: relative;
        max-width: 770px;

        img {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate(-50%, -50%);
        }
    }

    input {
        border: 1px solid var(--color-999999);
        color: var(--color-999999);
        font-family: Source Sans Pro, sans-serif;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 15px 10px 20px;
        width: 100%;
    }
`;

export const DrPhotoSection = styled.div`
    img {
        width: 100%;
        max-width: 400px;
        min-height: 228px;
    }
`;

export const DiseasesSection = styled.div`
    background-color: var(--bg-color);
    min-height: 652px;
    padding: 62px 0;

    .ThreeDotsSection {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 652px;
    }
`;

export const MenuList = styled.div`
    margin-top: 60px;
    padding-left: 0px !important;

    .dropdown {
        margin-bottom: 20px;

        .dropdown-item {
            span {
                width: 100%;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: left;
            }

            &.selected {
                background-color: #b5ffc0d1;
                color: var(--color-333333);
            }
        }

        button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-radius: 50px;
            margin-bottom: 20px;
            background-color: var(--color-edfaff);
            color: var(--color-333333);
            font-size: 18px;
            line-height: 20px;
            padding: 15px 20px 15px 30px;

            @media (max-width: 767.98px) {
                width: 300px;
            }

            svg {
                font-size: 25px;
                transform: rotate(270deg);
                color: var(--bg-color);
            }

            :hover {
                background-color: #b5ffc0d1;

                svg {
                    transform: rotate(360deg);
                    transition: all 0.6s ease-in-out;
                }
            }

            &.selected {
                background-color: #b5ffc0d1;
                color: var(--color-333333);

                &.dropdown-item {
                    background-color: #b5ffc0d1;

                    span {
                        width: 100%;
                        display: block;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        text-align: left;
                    }
                }

                svg {
                    transform: rotate(360deg);
                    transition: all 0.6s ease-in-out;
                }
            }

            span {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-weight: 500;
                font-size: 18px;
                color: var(--primary-color);
                max-width: calc(250px - 25px);
                width: 100%;
                display: block;
                text-overflow: ellipsis;
                text-align: left;
            }

            &:active {
                background-color: #edfaff;
                color: #333;
            }
        }

        .dropdown-toggle {
            &::after {
                content: unset;
            }
        }

        .dropdown-menu {
            &.show {
                overflow: auto;
                height: 200px;
                width: 100%;

                @media (max-width: 767.98px) {
                    width: 300px;
                }
            }
        }
    }
`;

export const LatestCard = styled.div`
    margin-top: 60px;
`;

export const DiseasesTitle = styled.h4`
    color: var(--white-color);
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 24px;
    margin-bottom: 0;
    text-transform: uppercase;
`;

export const CardStyle = styled(Card)`
    padding: 12px;
    border-radius: 16px !important;

    h5 {
        font-size: 26px;
        line-height: 30px;
        color: #3a3b40;
        font-family: "Source Sans Pro";
        font-weight: 600;
        margin-bottom: 0;
        padding-left: 16px;
    }

    .card-body {
        p {
            font-size: 18px;
            line-height: 24px;
            font-family: "Source Sans Pro";
            margin-bottom: 16px;
            color: #333;
            text-align: justify;
            color: #6c757d;
        }
    }
`;

export const TuberData = styled.p`
    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 5px 5px 0 0;
    padding: 3px 5px;
    border-radius: 7px;
    color: #ffffff !important;
    background-color: #37b24a !important;
    display: inline-block;
    cursor: pointer;
`;

export const LearnmoreBtn = styled.div`
    span {
        color: #3a3b40;
        font-size: 20px;
        line-height: 22px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        padding-right: 37px;
        position: relative;
        word-break: break-all;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        svg {
            font-size: 26px;
        }

        :hover {
            svg {
                -webkit-transform: translateX(0.25em);
                transform: translateX(0.25em);
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }
        }
    }
`;

export const LatestLeftCard = styled.div`
    span,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #333333 !important;
        text-align: justify !important;
    }

    p {
        color: #333333 !important;
        text-align: justify !important;
    }

    .tooltip-content {
        display: none;
    }

    strong {
        color: #333333 !important;
    }
    .highlight {
        color: #fff !important;
        &:hover .tooltip-content {
            h5 {
                color: #fff !important;
                padding-left: 0 !important;
            }
            display: block;
            position: absolute;
            background-color: black;
            color: #fff !important;
            border-radius: 8px;
            padding: 8px;
        }
    }
`;

export const LatestRightCard = styled.div`
    img {
        float: right;
        margin: 0 0 0 15px;
        width: 400px;
        height: 400px;
        max-width: 100%;
        object-fit: contain;
    }
`;
