import Logo from "../assets/images/logo.png";
import Left from "../assets/images/left.png";
import Right from "../assets/images/right.png";
import DrPhoto from "../assets/images/dr-photo.png";
import Search from "../assets/images/search.svg";
import FooterLogo from "../assets/images/last-logo.png";
import Demo from "../assets/images/demo.png";

export const SVGImages = {};

export const Images = {
    Logo,
    Left,
    Right,
    DrPhoto,
    Search,
    FooterLogo,
    Demo,
};
