import { AxiosResponse } from "axios";
import { useState } from "react";
import API from "../api";

const useFetch = <T>() => {
    const [data, setData] = useState<T | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown | null>(null);
    const [isError, setIsError] = useState(false);

    const run = async (url: string, loading = true) => {
        try {
            setIsLoading(loading);
            setError(null);
            setIsError(false);
            const response: AxiosResponse<T> = await API.get(url);
            setData(response?.data);
        } catch (error) {
            setError(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        data,
        setData,
        isLoading,
        error,
        isError,
        run,
    };
};

export default useFetch;
