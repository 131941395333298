import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Home from "../Pages/Home";
import useFetch from "../hooks/useFetch";
import { HomeContent } from "./types";
import { ThreeDots } from "react-loader-spinner";

const Dashboard = () => {
    const { run: getHomeContent, data: homeContent, isLoading } = useFetch<HomeContent[]>();

    useEffect(() => {
        getHomeContent("/common/homeContent");
    }, []);

    if (isLoading || !homeContent) {
        return (
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#009cde"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
                visible={true}
            />
        );
    }

    return (
        <div>
            <Header />
            <Home homeContent={homeContent?.[0]} />
            <Footer homeContent={homeContent?.[0]} />
        </div>
    );
};

export default Dashboard;
