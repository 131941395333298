import axios from "axios";
import { BASE_URL } from "../helper/utils";
import { store } from "../store/store";

const API = axios.create({
    baseURL: BASE_URL,
});

API.interceptors.request.use(
    function (config) {
        const token = store.getState().base.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    (response) => {
        if (response.status === 401) {
            console.log("unauthorized");
        }
        return response;
    },
    (error) => Promise.reject(error)
);

export default API;
