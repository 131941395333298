import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StateInterface } from "../../helper/interface";

const initialState: StateInterface = {
    token: "",
};

export const BaseSlice = createSlice({
    name: "base",
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
    },
});

export const { setToken } = BaseSlice.actions;
export default BaseSlice.reducer;
