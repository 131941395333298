import React, { useState } from "react";
import { Images } from "../../config/images";
import { HeaderSection } from "./styled";

const Header = () => {
    const [scroll, setScroll] = useState(false);

    const setScrollFunc = () => {
        const component = document.querySelector(".logo");
        if (component) {
            const sticky = component.ATTRIBUTE_NODE;
            if (window.pageYOffset > sticky) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        }
    };

    window.onscroll = () => setScrollFunc();

    return (
        <HeaderSection className={scroll ? "sticky" : ""}>
            <div className={"logo text-center"}>
                <a href="#">
                    <img src={Images.Logo} alt="logo" />
                </a>
            </div>
        </HeaderSection>
    );
};

export default Header;
