import React from "react";
import { Container, Row } from "react-bootstrap";
import { Images } from "../../config/images";
import { CopyRightSection, Disclaimer, FooterContent, FooterSection, Notes } from "./styled";
import { FooterProps } from "./types";

const Footer = ({ homeContent }: FooterProps) => {
    return (
        <FooterSection>
            <Container>
                <Row>
                    <FooterContent>
                        <Notes>
                            <strong>Notes:</strong> <span dangerouslySetInnerHTML={{ __html: homeContent?.footerNotes }} />
                        </Notes>
                        <Disclaimer>
                            <strong>disclaimer:</strong> <span dangerouslySetInnerHTML={{ __html: homeContent?.footerDisclaimer }} />
                        </Disclaimer>
                        <CopyRightSection>
                            <p>{homeContent?.footerContent}</p>
                            <a href="/">
                                <img src={Images.FooterLogo} alt="" />
                            </a>
                        </CopyRightSection>
                    </FooterContent>
                </Row>
            </Container>
        </FooterSection>
    );
};

export default Footer;
