import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes as ReactRoutes, Route } from "react-router-dom";
import Dashboard from "../container/Dashboard";

import API from "../api";
import { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../store/slice/BaseSlice";
import { AppDispatch, RootState } from "../store/store";
import { ThreeDots } from "react-loader-spinner";

const Routes = () => {
    const dispatch: AppDispatch = useDispatch();
    const { token } = useSelector((state: RootState) => state.base);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const handleGetToken = async () => {
        try {
            setIsLoading(true);
            setIsError(false);
            const response: AxiosResponse<string> = await API.get("/chapterDetail/token");
            dispatch(setToken(response?.data));
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetToken();
    }, []);

    if (isLoading) {
        return (
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#009cde"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
                visible={true}
            />
        );
    }

    if (isError || !token) {
        return <h1>Something went wrong</h1>;
    }

    return (
        <BrowserRouter>
            <ReactRoutes>
                <Route path="" element={<Dashboard />} />
            </ReactRoutes>
        </BrowserRouter>
    );
};

export default Routes;
