/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumb, Card, Col, Container, Dropdown, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Images } from "../../config/images";
import {
    CardStyle,
    DiseasesSection,
    DiseasesTitle,
    DrPhotoSection,
    HomePage,
    KnowledgeSection,
    LatestCard,
    LatestLeftCard,
    LatestRightCard,
    LearnmoreBtn,
    MenuList,
    SearchSection,
    TuberData,
} from "./styled";
import { HiChevronDown } from "react-icons/hi";
import { CgArrowRight } from "react-icons/cg";
import useFetch from "../../hooks/useFetch";
import { ChapterDetails, Diagnosis, HomePageProps, PageContentWithDiagnosis, ParentChapter, SubTopics } from "./types";
import { ThreeDots } from "react-loader-spinner";

const Home = ({ homeContent }: HomePageProps) => {
    const { data: chapters, run: getChapters, isLoading: isChapterLoading } = useFetch<ChapterDetails[]>();
    const { data: chaptersDetails, run: getChaptersDetails, setData: setChapterDetails } = useFetch<PageContentWithDiagnosis>();
    const [selectedSubTopic, setSelectedSubTopic] = React.useState<SubTopics | null>(null);
    const [selectedChapter, setSelectedChapter] = React.useState<ParentChapter | null>(null);
    const [pageContent, setPageContent] = useState<string>("");

    const diagnosis: Diagnosis[] = useMemo(() => {
        return JSON.parse(chaptersDetails?.diagnosis || "[]")?.filter((item: Diagnosis) =>
            chaptersDetails?.pageContent?.includes(item?.name)
        );
    }, [chaptersDetails?.diagnosis, chaptersDetails?.pageContent]);

    const scrollToSection = (id: string) => {
        window.scrollTo({
            top: document.getElementById(id)?.offsetTop,
            behavior: "smooth",
        });
    };

    const onLearnMoreClick = async (): Promise<void> => {
        if (selectedSubTopic?.parentChapter?.id) {
            scrollToSection("chapter");
            getChaptersDetails(`/chapterDetail/pageContentWithDiagnosis/${selectedSubTopic?.parentChapter?.id}`);
        }
    };

    const getDescriptionByCode = (name: string, list: any, codeKey: string) => {
        if (list && list?.length) {
            const listData = list?.slice(0, 5)?.reduce((acc: any, item: any) => {
                return acc + `<div>${item?.[codeKey]}: ${item?.description} </div>`;
            }, "");
            return `<h5>${name}</h5> ${listData}`;
        }
        return "";
    };

    const toolTip = (item: Diagnosis) => {
        return `<div>
                    <div>${getDescriptionByCode("ICD List", item?.icdList || [], "icdCode")}</div>
                    <div>${getDescriptionByCode("ICF List", item?.icfList || [], "icfCode")}</div>
                    <div>${getDescriptionByCode("OMIM List", item?.omimList || [], "omimCode")}</div>
                    <div> ${getDescriptionByCode("ORPHA List", item?.orphaList || [], "orphaCode")}</div>
                    <div>${getDescriptionByCode("snomed List", item?.snomedList || [], "snomedCode")}</div>
                </div>`;
    };

    const searchContent = (item: Diagnosis): string => {
        return `<div class='btn btn-secondary highlight'>
                   ${item?.name}
                    <span class='tooltip-content'>${toolTip(item)}</span>
                </div>`;
    };

    useEffect(() => {
        getChapters("/chapterDetail");
    }, []);

    useEffect(() => {
        if (chapters?.length) {
            setSelectedChapter(chapters[6].parentChapter);
            setSelectedSubTopic(chapters[6].subTopics[7]);
        }
    }, [chapters]);

    useEffect(() => {
        if (chaptersDetails?.pageContent && diagnosis) {
            let modifiedPageContent = chaptersDetails?.pageContent;
            diagnosis?.forEach((item) => {
                if (modifiedPageContent?.toLowerCase()?.includes(item?.name?.toLowerCase())) {
                    const regEx = new RegExp(`(?<!<h[1-6]>)(?<!<h[1-6]>.*)${item?.name}(?!.*</h[1-6]>)(?!.*</h[1-6]>.*</h[1-6]>)`, "gi");
                    modifiedPageContent = modifiedPageContent.replaceAll(regEx, searchContent(item));
                }
            });
            setPageContent(modifiedPageContent);
        }
    }, [chaptersDetails?.pageContent, diagnosis]);

    return (
        <HomePage>
            <SearchSection>
                <Container>
                    <Row className="align-items-center">
                        <Col md={8}>
                            <KnowledgeSection>
                                <p>{homeContent?.smallHeader1}</p>
                                <h2>{homeContent?.title}</h2>
                                <div className="search">
                                    <input type="text" id="search" className="rounded-pill" placeholder="Search" name="Search" />
                                    <img src={Images.Search} alt="" width="26px" height="26px" />
                                </div>
                            </KnowledgeSection>
                        </Col>
                        <Col md={4}>
                            <DrPhotoSection>
                                <img src={Images.DrPhoto} alt="" />
                            </DrPhotoSection>
                        </Col>
                    </Row>
                </Container>
            </SearchSection>
            <DiseasesSection id="chapter">
                {isChapterLoading ? (
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass="ThreeDotsSection"
                        visible={true}
                    />
                ) : (
                    <Container>
                        <Row>
                            <Col md={4} lg={3}>
                                <DiseasesTitle>{homeContent?.menuListHeader}</DiseasesTitle>
                                <MenuList>
                                    {chapters?.length &&
                                        chapters?.map((item) => (
                                            <Dropdown key={item?.parentChapter?.id}>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>{item?.parentChapter?.title}</Tooltip>}>
                                                    <Dropdown.Toggle
                                                        variant="success"
                                                        id="dropdown-basic"
                                                        className={selectedChapter?.id === item?.parentChapter?.id ? "selected" : ""}
                                                    >
                                                        <span>{item?.parentChapter?.title}</span>
                                                        <HiChevronDown />
                                                    </Dropdown.Toggle>
                                                </OverlayTrigger>
                                                <Dropdown.Menu>
                                                    {item?.subTopics?.map((subTopic) => (
                                                        <Dropdown.Item
                                                            key={subTopic?.parentChapter?.id}
                                                            onClick={() => {
                                                                scrollToSection("chapter");
                                                                setSelectedChapter(item?.parentChapter);
                                                                setSelectedSubTopic(subTopic);
                                                                setChapterDetails(null);
                                                            }}
                                                            className={
                                                                selectedSubTopic?.parentChapter?.id === subTopic?.parentChapter?.id
                                                                    ? "selected"
                                                                    : ""
                                                            }
                                                        >
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip>{subTopic?.parentChapter?.title}</Tooltip>}
                                                            >
                                                                <span>{subTopic?.parentChapter?.title}</span>
                                                            </OverlayTrigger>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ))}
                                </MenuList>
                            </Col>
                            <Col md={8} lg={9}>
                                <DiseasesTitle>{homeContent?.menuContentHeader}</DiseasesTitle>
                                <LatestCard>
                                    <CardStyle>
                                        <h5>
                                            <Breadcrumb>
                                                <Breadcrumb.Item active>{selectedChapter?.title}</Breadcrumb.Item>
                                                <Breadcrumb.Item active>{selectedSubTopic?.parentChapter?.title}</Breadcrumb.Item>
                                            </Breadcrumb>
                                        </h5>
                                        <Card.Body>
                                            <LatestLeftCard>
                                                {selectedSubTopic?.parentChapter?.descImage && (
                                                    <LatestRightCard>
                                                        <img src={selectedSubTopic?.parentChapter?.descImage} alt="baby_photo" />
                                                    </LatestRightCard>
                                                )}
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            (chaptersDetails?.pageContent
                                                                ? pageContent
                                                                : selectedSubTopic?.parentChapter?.description) || "",
                                                    }}
                                                ></p>
                                                {!chaptersDetails?.pageContent && (
                                                    <LearnmoreBtn>
                                                        <span onClick={onLearnMoreClick}>
                                                            Learn more
                                                            <CgArrowRight />
                                                        </span>
                                                    </LearnmoreBtn>
                                                )}
                                            </LatestLeftCard>
                                            {diagnosis?.map((item) => (
                                                <TuberData key={item?.name}>{item?.name}</TuberData>
                                            ))}
                                        </Card.Body>
                                    </CardStyle>
                                </LatestCard>
                            </Col>
                        </Row>
                    </Container>
                )}
            </DiseasesSection>
        </HomePage>
    );
};

export default Home;
